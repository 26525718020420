import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BeatLoader } from "react-spinners";
import FileDropzone from "../../Components/Custom/FileDropzone";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import MapSurveyData from "./mapModal";
import * as XLSX from "xlsx";
import { isEmpty } from "lodash";
import { PostExternalSurveyData } from "../../helpers/custom_backend_helper";

const defaultValues = { providerName: "", dataCut: "", file: "", mapper: {} };

export const ImportSurveyData = ({
  show,
  onClick,
  onCloseClick,
  modalType,
  activeData,
  activeDataCut,
}) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [mapModal, setMapModal] = useState(false);
  const [externalColumns, setExternalColumns] = useState([]);
  const [externalFile, setExternalFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setExternalFile(null);
  }, [show]);

  function handleAcceptedFiles(acceptedFiles, setFieldValue) {
    setLoading(true);
    const file = acceptedFiles[0];
    if (file) {
      setFieldValue("file", file);
      setExternalFile(file);

      const reader = new FileReader();
      reader.onload = function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Function to get columns from a specific sheet
        const getColumnsFromSheet = (sheetName, threshold) => {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          // Find the header which satisfies ( length > threshold ) at first occurance
          let headerRow = jsonData.find((row) => row?.length > threshold);
          return headerRow || [];
        };

        const sheetNamesToCheck = ["compensation data", "job descriptions"];
        let allColumns = [];

        workbook.SheetNames.forEach((sheetName) => {
          if (sheetNamesToCheck.includes(sheetName.toLowerCase())) {
            let length =
              sheetName?.toLocaleLowerCase() == "job descriptions" ? 10 : 15;
            const columns = getColumnsFromSheet(sheetName, length);
            allColumns = allColumns.concat(columns);
          }
        });
        setExternalColumns(allColumns);
        setLoading(false);
      };
      reader.readAsArrayBuffer(file);
    }
  }

  const validateSchema = Yup.object().shape({
    providerName: Yup.mixed().required("Provider Name is required"),
    dataCut: Yup.mixed().required("Data Cut is required"),
    file: Yup.mixed().required("File is required"),
    mapper: Yup.mixed(),
  });

  const handleSubmitDatacut = (values) => {
    const formData = new FormData();
    formData.append("providerName", activeData?.providerName);
    formData.append("dataCut", values?.dataCut);
    formData.append("file", values?.file);
    PostExternalSurveyData(formData)
      .then((res) => {
        if (
          res?.metadata?.status === 200 ||
          res?.metadata?.message == "Success"
        ) {
          console.log("SUCCESS");
          onCloseClick();
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
      });
  };

  return (
    <div>
      <Modal
        fade={true}
        isOpen={show}
        toggle={onCloseClick}
        centered={true}
        className="border border-0"
        modalClassName="flip"
      >
        <ModalHeader
          className="bg-secondary p-3 "
          toggle={() => onCloseClick(!show)}
        >
          <span className="mb-0 h5 text-white">
            Add Survey {modalType == "data-set" ? "Data Set" : "Datacut File"}
          </span>
        </ModalHeader>
        <ModalBody className="p-3">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validateSchema}
          >
            {({
              errors,
              handleChange,
              values,
              setFieldValue,
              isSubmitting,
              dirty,
              setFieldTouched,
              setFieldError,
            }) => {
              useEffect(() => {
                if (modalType == "data-sub-set") {
                  setFieldValue("providerName", activeData?.providerName);
                }
                if (activeDataCut) {
                  setFieldValue("dataCut", activeDataCut);
                }
              }, [modalType, show]);
              return (
                <>
                  <Row>
                    {modalType == "data-set" && (
                      <Col md={12} className="mb-4">
                        <Label>External Survey Data Name</Label>
                        <Input
                          className="form-control"
                          id="providerName"
                          name="providerName"
                          type="text"
                          placeholder="ex. Radford"
                          onChange={handleChange}
                          onBlur={() => {
                            setFieldTouched("providerName", true);
                            setFieldError("providerName", true);
                          }}
                        />
                        <ErrorMessage
                          name="providerName"
                          component="div"
                          className="error text-danger"
                        />
                      </Col>
                    )}
                    <Col md={12} className="mb-4">
                      <Label> Data Cut Name</Label>
                      <Input
                        className="form-control"
                        id="dataCut"
                        name="dataCut"
                        type="text"
                        placeholder="ex. E-commerce Data"
                        onChange={handleChange}
                        onBlur={() => {
                          setFieldTouched("dataCut", true);
                          setFieldError("dataCut", true);
                        }}
                        value={values?.dataCut}
                      />
                      <ErrorMessage
                        name="dataCut"
                        component="div"
                        className="error text-danger"
                      />
                    </Col>
                  </Row>
                  {externalFile && !loading ? (
                    <div className="bg-light p-2 d-flex align-items-center justify-content-between rounded">
                      <h6 className="mb-0">{externalFile?.path}</h6>
                      <i
                        role="button"
                        class="bx bx-x-circle text-danger fs-5"
                        onClick={() => setExternalFile(null)}
                      ></i>
                    </div>
                  ) : (
                    <div className="mb-4">
                      <FileDropzone
                        handleAcceptedFiles={handleAcceptedFiles}
                        className="Dropzone_File mb-3"
                        loading={loading}
                        setFieldValue={setFieldValue}
                        // loading={loading}
                      />

                      <p className="text-muted">
                        Note : Sheet name must be <mark className="fw-bol px-2">Compensation Data</mark>
                      </p>
                    </div>
                  )}
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      className="btn btn-sm theme-cancel-btn mx-2 px-4 fs-6"
                      color="white"
                      onClick={() => onCloseClick(!show)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn px-4"
                      color="secondary"
                      onClick={() => {
                        if (modalType == "data-sub-set") {
                          handleSubmitDatacut(values);
                        } else setMapModal(!mapModal);
                      }}
                      disabled={
                        isSubmitting ||
                        !isEmpty(errors) ||
                        !dirty ||
                        !(externalFile ? true : false)
                      }
                    >
                      {modalType == "data-set" ? "Map Fields" : "Save"}
                    </Button>
                  </div>
                  <MapSurveyData
                    show={mapModal}
                    onCloseClick={setMapModal}
                    onClick={() => {}}
                    externalColumns={externalColumns}
                    formValues={values}
                  />
                </>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ImportSurveyData;
